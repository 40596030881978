<i18n>
  {
    "en": {
      "Nimbuzz Pay Charges": "Nimbuzz Pay Charges",
      "Wallet to Bank": "Wallet to Bank",
      "Amount": "Amount",
      "Charge": "Charge",
      "Nepal Electricity": "Nepal Electricity",
      "Credit Card Payment": "Credit Card Payment",
      "Government Services": "Government Services",
      "Rs.": "Rs.",
      "Any": "Any"
    },
    "np": {
      "Nimbuzz Pay Charges": "निम्बज पे शुल्कहरू",
      "Wallet to Bank": "वालेट देखि बैंक",
      "Amount": "रकम",
      "Charge": "शुल्क",
      "Nepal Electricity": "नेपाल विद्युत प्राधिकरण",
      "Credit Card Payment": "क्रेडिट कार्ड भुक्तानी",
      "Government Services": "Government Services",
      "Rs.": "रु.",
      "Any": "कुनै पनि"
    }
  }
</i18n>
<template lang="pug">
  .hero-banner
    .hero-banner-content
      .container.mb-5
        .card
          .modal-header
            h5.font-weight-bold {{ $t("Nimbuzz Pay Charges") }}
          .modal-body
            div.mb-5(v-for="i in charges" :key="i.title")
              h6.font-weight-bold {{i.title}}
              b-table.font-small.text-nowrap(:fields="i.fields" :items="i.items" small fixed)
    .hero-silhouette
    
</template>

<script>
export default {
  name: "charges",
  computed: {
    charges() {
      let currency = this.$t("Rs.")
      let fields = [
      {
        key: "amount",
        label: this.$t("Amount"),
      },
      {
        key: "charge",
        label: this.$t("Charge"),
      },
    ]
      return [
        {
          title: this.$t("Wallet to Bank"),
          fields: fields,
          items: [
            {
              amount: "10-1000",
              charge: "0.56%",
            },
            {
              amount: "1001-100000",
              charge: currency  + "9.04",
            },
          ],
        },
        {
          title: "Wallet to Wallet",
          fields: fields,
          items: [
            {
              amount: "Any Amount",
              charge: currency  + "0",
            },
          ],
        },
        {
          title: "Credit Card Payment",
          fields: fields,
          items: [
            {
              amount: "0-500",
              charge: currency  + "5",
            },
            {
              amount: "500.01-5000",
              charge: currency  + "10",
            },
            {
              amount: "5000.01-10000",
              charge: currency  + "15",
            },
            {
              amount: "10000.01-20000",
              charge: currency  + "20",
            },
            {
              amount: "20000.01-30000",
              charge: currency  + "30",
            },
            {
              amount: "30000.01-50000",
              charge: currency  + "40",
            },
            {
              amount: "50000.01-100000",
              charge: currency  + "50",
            },
          ],
        },
        {
          title: "Nepal Electricity",
          fields: fields,
          items: [
            {
              amount: "0-500",
              charge: currency  + "0",
            },
            {
              amount: "500.01-100000",
              charge: currency  + "5",
            },
          ],
        },
        {
          title: "Government Services",
          fields: fields,
          items: [
            {
              amount: this.$t("Any"),
              charge: currency  + "5",
            },
          ],
        },
      ]
    }
  }
};
</script>

<style scoped>
.error {
  text-align: center;
  color: red;
}
</style>
